import './bootstrap';

import Alpine from 'alpinejs';
import persist from '@alpinejs/persist'

window.Alpine = Alpine;

Alpine.plugin(persist);

Alpine.data('helpers', () => ({
    convertDate: (dateStr) => {
        // return new Date(date).toLocaleDateString('en-US', {
        //     year: 'numeric',
        //     month: 'long',
        //     day: 'numeric',
        // });
        const date = new Date(dateStr);
        // const formattedDate = date.toLocaleDateString('ja-JP').replaceAll('/', '-');
        // return formattedDate;
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const formattedDate = year + "/" + month + "/" + day;
        return formattedDate;
    }
}));

Alpine.start();
